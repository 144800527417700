<script setup>
import { ref } from "vue";
import { useRouter } from "vue-router";

import AccountQuestionIcon from "vue-material-design-icons/AccountQuestionOutline.vue";
import AccountBoxIcon from "vue-material-design-icons/AccountBoxOutline.vue";
import AccountBriefcaseIcon from "vue-material-design-icons/BriefcaseAccountOutline.vue";

const router = useRouter();

const showMenu = ref(false);

const closeMenu = () => {
  showMenu.value = false;
};

const goHome = () => {
  router.push("/");
};
</script>

<template>
  <header>
    <div class="container">
      <img
        class="pointer"
        @click="goHome"
        src="@/assets/images/logo-white.png"
      />

      <div @click.stop="showMenu = true" class="menu-button desktop-hidden">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
      </div>

      <transition
        enter-active-class="animated zoomIn"
        leave-active-class="animated zoomOut"
      >
        <nav v-click-outside="closeMenu" v-show="showMenu">
          <ul>
            <li>
              <router-link to="/quem-somos" class="create-account">
                <AccountQuestionIcon fillColor="var(--primary)" />
                <p>Quem somos</p>
              </router-link>
            </li>
            <li>
              <a
                href="https://app.festeje.ai/#/login"
                target="_blank"
                rel="noopener noreferrer"
                class="create-account"
              >
                <AccountBoxIcon fillColor="var(--primary)" />
                <p>Área do cliente</p>
              </a>
            </li>
            <li>
              <a
                href="https://prestador.festeje.ai/#/login"
                target="_blank"
                rel="noopener noreferrer"
                class="create-account"
              >
                <AccountBriefcaseIcon fillColor="var(--primary)" />
                <p>Área do fornecedor</p>
              </a>
            </li>
          </ul>
        </nav>
      </transition>

      <div class="items-container mobile-hidden tablet-hidden">
        <div class="menu-container">
          <router-link to="/quem-somos" class="btn inverse mt-0">
            <span>Quem somos</span>
          </router-link>
          <a
            href="https://prestador.festeje.ai/#/login"
            target="_blank"
            rel="noopener noreferrer"
            class="btn inverse border-yellow mt-0"
          >
            <span>Área do fornecedor</span>
          </a>
          <a
            href="https://app.festeje.ai/#/login"
            target="_blank"
            rel="noopener noreferrer"
            class="btn bg-yellow mt-0"
          >
            <span>Área do cliente</span>
          </a>
        </div>
      </div>
    </div>
  </header>
</template>

<style lang="scss" scoped>
header {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 0;
  padding: 18px 0;
  background-color: var(--primary);
  z-index: 99;
}

header img {
  position: relative;
  width: 12%;
  object-fit: cover;
  @media only screen and (max-width: 720px) {
    width: 30%;
  }
}

.container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.items-container {
  position: relative;
  width: fit-content;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 90px;
  margin-left: auto;
}

.menu-container {
  position: relative;
  width: fit-content;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  .btn {
    border-radius: 1.8em;
    padding: 8px 26px;
    span {
      color: #f5f5f5;
      font-size: 0.9em;
    }
    &.border-yellow {
      border: 1px solid var(--secondary);
      span {
        color: var(--secondary);
      }
    }
    &.bg-yellow {
      span {
        color: var(--primary);
      }
    }
  }
}

.menu-button {
  position: relative;
  width: 28px;
  height: 18px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-left: auto;
  .line {
    position: relative;
    width: 100%;
    height: 2px;
    background-color: #fff;
  }
}

nav {
  position: absolute;
  top: 40px;
  right: 0px;
  width: 240px;
  padding: 15px;
  border-radius: 0.5rem;
  background-color: #fff;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.25);
  transform-origin: top right;

  ul li {
    position: relative;
    width: 100%;
    height: 50px;

    &:not(:last-child) {
      border-bottom: 1px solid #00000015;
    }

    a,
    div {
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      p {
        font-family: fontLight;
        font-size: 1rem;
        margin-left: 15px !important;
        color: var(--primary);
      }

      svg {
        font-size: 1.3em !important;
      }
    }
  }
}
</style>
