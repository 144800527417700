import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import VueAnimateOnScroll from "vue3-animate-onscroll";
import vClickOutside from "click-outside-vue3";
import VueSelect from "vue-select";
import "vue-select/dist/vue-select.css";

import "./assets/css/desktop.css";
import "./assets/css/mobile.css";
import "./assets/css/tablet.css";
import "./assets/css/animate.css";

const app = createApp(App);

app.component("v-select", VueSelect);
app.use(router);
app.use(VueAnimateOnScroll);
app.use(vClickOutside);
app.mount("#app");
