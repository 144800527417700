<script setup>
import { ref } from "vue";
import http from "@/http";
import MagnifyIcon from "vue-material-design-icons/Magnify.vue";

const locations = ref([]);
const selectedLocation = ref("");
const products = ref([
  "Bebidas",
  "Bolo",
  "Brinquedos",
  "Buffet e gastronomia",
  "Churrasqueiro",
  "Decoração",
  "DJ e Música ao vivo",
  "Doces",
  "Estruturas e Iluminação",
  "Equipe de Animação",
  "Espaços",
  "Fotografia e Filmagem",
  "Lembranças Personalizadas",
  "Salgados",
]);
const selectedProduct = ref("");
const loading = ref(false);
const warningMessage = ref(false);

const getLocations = (value) => {
  if (value.length < 3) return;

  loading.value = true;
  http
    .get(`location?search=${value}`)
    .then((response) => {
      locations.value = response?.data.length > 0 ? response?.data : [];
    })
    .catch((err) => {
      console.log(err);
    })
    .finally(() => {
      loading.value = false;
    });
};

const sendToPage = () => {
  if (!selectedProduct.value && !selectedLocation.value) {
    warningMessage.value = true;
    return;
  }

  selectedProduct.value = selectedProduct.value || "";
  selectedLocation.value = selectedLocation.value || "";

  window.open(
    `https://app.festeje.ai/#/?product=${selectedProduct.value}&location=${selectedLocation.value}`,
    "_blank"
  );
};
</script>
<template>
  <div class="modal-holder">
    <img src="@/assets/images/search.png" class="icon-search mobile-hidden" />
    <div class="modal-content">
      <span class="header-modal">
        Encontre tudo o que você precisa para sua festa ou evento
      </span>
      <div class="footer-modal">
        <div class="input-holder">
          <div class="input-item mt-0">
            <v-select
              v-model="selectedProduct"
              :options="products"
              placeholder="O que você procura?"
              taggable
            />
          </div>
          <div class="input-item mt-0">
            <v-select
              @search="getLocations"
              v-model="selectedLocation"
              :options="locations"
              :reduce="(local) => local.value"
              :searchable="true"
              :loading="loading"
              label="label"
              :filterable="false"
              placeholder="Qual localidade?"
            >
              <!-- eslint-disable-next-line vue/no-unused-vars  -->
              <template #no-options="{ search, searching, loading }">
                <span style="opacity: 0.5">Comece a digitar para filtrar.</span>
              </template>
            </v-select>
          </div>
        </div>
        <div @click="sendToPage" class="btn icon">
          <span>Buscar</span>
          <MagnifyIcon fillColor="var(--primary)" />
        </div>
      </div>
      <div v-show="warningMessage" class="warning animated fadeInDown">
        <span
          >* Por favor, preencha pelo menos um campo para efetuar a busca.</span
        >
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.modal-holder {
  z-index: 99;
  position: relative;
  display: flex;
  gap: 30px;
  width: 70vw;
  top: 50%;
  transform: translateY(-50%);
  background-color: #fff;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0 auto;
  padding: 1.5rem 2.5rem 2.5rem 2.5rem;
  border-radius: 16px;
  box-shadow: var(--box-shadow-default);
  @media only screen and (max-width: 720px) {
    width: 85vw;
    padding: 1.2rem 1.6rem 2rem 1.6rem;
    transform: translateY(-20%);
  }

  .icon-search {
    position: relative;
    width: 52px;
    height: 52px;
    object-fit: cover;
  }

  .modal-content {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 30px;
    @media only screen and (max-width: 720px) {
      gap: 25px;
    }
  }

  .header-modal {
    font-size: 1.1rem;
    @media only screen and (max-width: 720px) {
      font-size: 0.95rem;
      text-align: center;
    }
  }

  .footer-modal {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 35px;
    @media only screen and (max-width: 720px) {
      flex-direction: column;
      gap: 25px;
    }
    .input-holder {
      position: relative;
      width: 80%;
      display: flex;
      gap: 35px;
      @media only screen and (min-width: 721px) and (max-width: 1139px) {
        flex-direction: column;
      }
      @media only screen and (max-width: 720px) {
        width: 100%;
        gap: 15px;
        flex-direction: column;
        select {
          font-size: 0.85rem;
        }
      }
      .input-item input,
      .input-item select {
        background-color: transparent;
        border: 1px solid #ccc;
        &::placeholder {
          font-size: 0.9rem;
          @media only screen and (max-width: 720px) {
            font-size: 0.85rem;
          }
        }
      }
    }
    .btn {
      margin: 0 !important;
      padding: 8px 22px;
      border-radius: 8px;
      @media only screen and (max-width: 720px) {
        width: 100%;
      }
      span {
        font-size: 0.95rem;
      }
    }
  }

  .warning {
    position: relative;
    span {
      font-family: fontLight;
      font-size: 0.8rem;
      color: var(--red1);
    }
  }
}
</style>
