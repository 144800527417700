<script setup>
import { ref } from "vue";
import http from "@/http";
import { Navigation, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/navigation";

const banners = ref([]);

http
  .get("banner?page=site-institucional&orderBy=-timestamp")
  .then((response) => {
    banners.value = response.data;
  })
  .catch((err) => {
    console.log(err);
  });

const swiperInstance = ref(null);

const setSwiper = (swiper) => {
  swiperInstance.value = swiper;
  setTimeout(() => {
    swiperInstance.value.slideTo(0);
  }, 250);
};
</script>
<template>
  <swiper
    :modules="[Navigation, Autoplay]"
    navigation
    :autoplay="{
      delay: 5000,
      disableOnInteraction: false,
    }"
    :slides-per-view="1"
    :space-between="0"
    :observer="true"
    :observeParents="true"
    :grab-cursor="true"
    class="slider"
    @swiper="setSwiper"
  >
    <swiper-slide
      v-for="(banner, index) in banners"
      :key="index"
      class="animated fadeIn slide-item"
    >
      <picture>
        <source :srcset="banner.fileMobile" media="(max-width: 720px)" />
        <source :srcset="banner.file" />
        <img :srcset="banner.file" alt="Banner" />
      </picture>
    </swiper-slide>
  </swiper>
</template>
<style lang="scss" scoped>
.slide-item {
  position: relative;
  background: transparent !important;
  border: none;
  padding: 0 !important;
  max-height: 440px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 720px) {
    max-height: 280px;
  }
  img {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
</style>
