<script setup>
import { ref } from "vue";

import DownArrowIcon from "vue-material-design-icons/ChevronDown.vue";

const faq = ref([
  {
    id: "faq1",
    showAnswer: false,
    question: "Quais são os benefícios do cadastro de fornecedor?",
    answer:
      "<ul><li style='list-style: decimal'><b>Sem custo de investimento inicial:</b> Para ter acesso a utilizar a Plataforma Festeje aí, não é necessário investimento financeiro, basta acessar os links para cadastramento, configurar sua empresa e ser mostrar para o mundo.</li><li style='list-style: decimal'><b>Maior visibilidade da marca, produtos e serviços:</b> Ao se cadastrar na Plataforma Festeje aí, você terá a oportunidade de aumentar sua visibilidade junto ao público-alvo. As pessoas que estão planejando uma festa poderão encontrar seu negócio mais facilmente, o que pode resultar em um aumento nas reservas e nas vendas.</li><li style='list-style: decimal'><b>Aumento no número de eventos fechados:</b> Com a exposição adicional proporcionada pela Plataforma Festeje aí, você terá acesso a uma base de clientes mais ampla. Dessa forma, você poderá alcançar pessoas que talvez não estivessem cientes da existência do seu negócio anteriormente.</li><li style='list-style: decimal'><b>Avaliações e feedback dos clientes:</b> A Plataforma Festeje aí permite que os clientes avaliem e deixem feedback sobre os fornecedores com os quais tiveram experiências. Isso pode ser extremamente valioso para a construção de sua reputação online. Avaliações positivas e comentários elogiosos podem atrair novos clientes e aumentar a confiança dos consumidores em relação ao seu negócio.</li><li style='list-style: decimal'><b>Promoção efetiva:</b> Através de um serviço pago, a Plataforma Festeje aí irá oferecer recursos de promoção e publicidade para os fornecedores cadastrados que tiverem interesse em se destacar a visibilidade de seus produtos e serviços de forma estratégica na Plataforma, através de listas de fornecedores, banners promocionais, compartilhamento em redes sociais e outras estratégias de marketing digital. Essas promoções podem ajudar a impulsionar seu negócio e atrair mais clientes.</li></ul>",
  },
  {
    id: "faq2",
    showAnswer: false,
    question: "A plataforma já está disponível?",
    answer:
      "Sim! Se você possui uma empresa de eventos ou oferece serviços relacionados, já pode se cadastrar e começar a anunciar. Em breve, a plataforma será aberta para os clientes com através de uma mega campanha de marketing e divulgação.",
  },
  {
    id: "faq3",
    showAnswer: false,
    question: "Há algum custo para utilizar a ferramenta?",
    answer:
      "A Plataforma oferece um plano totalmente grátis para os fornecedores cadastrados. Além do plano gratuito, posteriormente será oferecido também um plano pago contendo vários benefícios adicionais.",
  },
  {
    id: "faq4",
    showAnswer: false,
    question: "Posso divulgar a plataforma para outras pessoas?",
    answer:
      " Sim! A plataforma está pronta para receber fornecedores dos segmentos eventos e festas de todo o país.",
  },
  {
    id: "faq5",
    showAnswer: false,
    question: "Tenho outra dúvida, posso falar com alguém?",
    answer: "Sim!! Através do e-mail marketing@festeje.ai",
  },
]);

const faqToggle = (item) => {
  const faqItem = document.querySelector(`#faq${item.id}`);
  const height = faqItem.querySelector(".body div").offsetHeight;

  item.showAnswer = !item.showAnswer;
  faqItem.querySelector(".body").style.height = item.showAnswer
    ? `${height}px`
    : "0px";
};
</script>
<template>
  <section class="faq py-2">
    <img
      class="person-icon mobile-hidden"
      src="@/assets/images/personFooter.png"
    />
    <div class="container">
      <div class="left-holder">
        <span class="title"> Quer saber mais sobre o Festeje aí? </span>
        <span class="subtitle">
          As dúvidas mais frequentes sobre a plataforma respondidas para você.
          Navegue pelas questões ou entre em contato com nossa equipe!
        </span>
        <div
          v-for="item in faq"
          :key="item.id"
          :id="`faq${item.id}`"
          class="faq-item"
          v-animate-onscroll="'animated zoomIn'"
        >
          <div class="header" @click="faqToggle(item)">
            <p :style="[item.showAnswer ? { 'font-family': 'fontBold' } : '']">
              {{ item.question }}
            </p>
            <DownArrowIcon
              :class="{ opened: item.showAnswer }"
              style="transition: transform 0.25s ease"
            />
          </div>
          <div class="body">
            <div class="faq-answer" v-html="item.answer"></div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<style lang="scss" scoped>
.faq {
  position: relative;
  background-color: var(--secondary);
  margin: 0 auto;

  .person-icon {
    position: absolute;
    width: 25%;
    right: 0;
    bottom: 0;
    object-fit: cover;
  }

  .left-holder {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 75%;
    justify-content: flex-start;
    align-items: start;
    @media only screen and (max-width: 720px) {
      width: 100%;
      justify-content: center;
      align-items: center;
    }

    .title {
      font-size: 1.5rem;
      color: var(--primary);
      margin-bottom: 12px;
      @media only screen and (max-width: 720px) {
        text-align: center;
      }
    }

    .subtitle {
      color: var(--primary);
      margin-bottom: 50px;
      @media only screen and (max-width: 720px) {
        text-align: center;
      }
    }

    .faq-item {
      position: relative;
      width: 100%;
      background-color: var(--secondary);
      border: 1px solid var(--primary);
      border-radius: 12px;
      margin-bottom: 16px;
      .header {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 30px;
        cursor: pointer;
        p {
          font-family: fontMedium;
          font-size: 1rem;
          color: var(--primary);
          @media screen and (max-width: 720px) {
            font-size: 1.1rem;
          }
        }
        .opened {
          transform: rotate(180deg);
        }
      }
      .body {
        position: relative;
        width: 100%;
        overflow: hidden;
        height: 0;
        transition: height 0.3s ease;
        .faq-answer {
          position: relative;
          font-size: 0.9rem;
          line-height: 1.5rem;
          padding: 0px 24px 16px 24px;
        }
      }
    }
  }
}
</style>
