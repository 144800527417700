<script setup></script>
<template>
  <section class="content pt-1 pb-3">
    <div class="container">
      <div
        class="who-we-are py-2 animated fadeIn"
        style="animation-delay: 200ms"
      >
        <div class="left">
          <span class="title">Quem somos</span>
          <span class="description">
            Bem-vindo ao <b>Festeje aí.</b> Se você está procurando um lugar
            para encontrar fornecedores confiáveis e serviços de qualidade para
            seu próximo evento, você está no lugar certo. Nós acreditamos que a
            chave para um evento bem-sucedido é encontrar os fornecedores
            certos, e nós estamos aqui para ajudar a facilitar esse processo.
          </span>
          <span class="title mt-1 tablet-hidden mobile-hidden">
            Nossa Missão
          </span>
          <span class="description tablet-hidden mobile-hidden">
            A nossa missão é facilitar a conexão entre clientes e fornecedores
            de produtos e serviços de festas e eventos, promovendo o crescimento
            e o sucesso de negócios dos nossos parceiros, enquanto oferecemos
            uma experiência de festa e evento para nossos usuários, facilitando
            a organização de celebrações memoráveis.
          </span>
        </div>
        <div class="right">
          <img src="../../assets/images/who-we-are.jpg" alt="quem somos" />
        </div>
      </div>
      <div
        class="our-mission py-2 desktop-hidden"
        v-animate-onscroll="'animated fadeInLeft'"
        style="animation-delay: 250ms"
      >
        <span class="title">Nossa Missão</span>
        <span class="description">
          A nossa missão é facilitar a conexão entre clientes e fornecedores de
          produtos e serviços de festas e eventos, promovendo o crescimento e o
          sucesso de negócios dos nossos parceiros, enquanto oferecemos uma
          experiência de festa e evento para nossos usuários, facilitando a
          organização de celebrações memoráveis.
        </span>
      </div>
      <div
        class="our-vision py-2"
        v-animate-onscroll="'animated fadeInRight'"
        style="animation-delay: 300ms"
      >
        <span class="title">Nossa Visão</span>
        <span class="description">
          Nossa visão é ser a plataforma de referência para a organização de
          festas e eventos em todo o país, reconhecida por sua diversidade de
          fornecedores, qualidade de serviços e compromisso em apoiar o
          crescimento dos nossos parceiros. Queremos ser reconhecidos como um
          hub de inspiração, conhecimento e conexões significativas no universo
          dos eventos.
        </span>
      </div>
      <div
        class="our-values py-2"
        v-animate-onscroll="'animated fadeInLeft'"
        style="animation-delay: 350ms"
      >
        <span class="title">Nossos Valores</span>
        <ul>
          <li>
            <b>Conectividade:</b> Nos esforçamos para conectar clientes com os
            fornecedores ideais, promovendo relacionamentos duradouros e
            vantajosos para ambas as partes.
          </li>
          <li>
            <b>Qualidade:</b> Nos comprometemos a oferecer apenas os melhores
            produtos e serviços, avaliando cuidadosamente nossos fornecedores e
            buscando constantemente a melhoria.
          </li>
          <li>
            <b>Suporte:</b> Nos dedicamos a ajudar nossos parceiros a expandirem
            sua presença online e atingirem um público maior, contribuindo para
            o desenvolvimento econômico local.
          </li>
          <li>
            <b>Inovação:</b> Buscamos estar à frente do mercado, oferecendo
            soluções tecnológicas inovadoras que simplifiquem o processo de
            planejamento e organização de festas e eventos.
          </li>
          <li>
            <b>Satisfação do cliente:</b> Nossa principal prioridade é garantir
            a plena satisfação de nossos clientes, fornecendo assistência
            personalizada e resolvendo quaisquer dúvidas ou problemas que possam
            surgir.
          </li>
          <li>
            <b>Paixão pelo que fazemos:</b> Somos apaixonados por festas e
            eventos, e essa paixão reflete-se em nosso compromisso de oferecer
            uma experiência excepcional para nossos clientes e fornecedores.
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>
<style lang="scss" scoped>
section.content {
  position: relative;
  margin: 0 auto;
  background-color: #fff;
}

.title {
  font-family: fontBold;
  font-size: 1.5rem;
  color: var(--secondary);
}
.description {
  font-size: 1.05rem;
  color: var(--primary);
}

.who-we-are {
  position: relative;
  width: 100%;
  display: flex;
  align-items: start;
  gap: 3rem;
  @media only screen and (max-width: 720px) {
    flex-direction: column-reverse;
  }
  .left {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
    @media only screen and (max-width: 720px) {
      width: 100%;
    }
  }
  .right {
    width: 50%;
    @media only screen and (max-width: 720px) {
      width: 100%;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.our-mission,
.our-vision,
.our-values {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
}

ul {
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto auto;
  gap: 1rem;
  @media only screen and (max-width: 720px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    padding: 0.8rem;
  }
  li {
    list-style: decimal !important;
    &::marker {
      color: var(--secondary);
    }
    b {
      font-family: fontBold;
      color: var(--secondary);
    }
  }
}
</style>
