<script setup></script>
<template>
  <section class="video pt-1 pb-2 animated fadeInDown">
    <video class="video" muted autoplay>
      <source src="../../../public/videos/quem-somos.mp4" type="video/mp4" />
    </video>
  </section>
</template>
<style lang="scss" scoped>
section.video {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: #fff;
  .video {
    border-radius: 22px;
    width: 30%;
    @media only screen and (max-width: 720px) {
      width: 90%;
    }
  }
}
</style>
