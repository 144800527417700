<script setup>
import { ref } from "vue";

const videos = ref([
  {
    title: "O que faz a plataforma <br /> Festeje aí?",
    video: require("../../../public/videos/plataforma-geral.mp4"),
    image: require("@/assets/images/video1.png"),
  },
  {
    title: "Quais são os benefícios <br /> para os clientes?",
    video: require("../../../public/videos/visao-cliente.mp4"),
    image: require("@/assets/images/video3.png"),
  },
  {
    title: "Quais são os benefícios <br /> para os fornecedores?",
    video: require("../../../public/videos/visao-fornecedor.mp4"),
    image: require("@/assets/images/video2.png"),
  },
]);
</script>
<template>
  <section class="videos pt-1 pb-3">
    <div class="container">
      <div class="video-holder">
        <div
          class="video-item"
          v-for="(item, index) in videos"
          :key="index"
          v-animate-onscroll="'animated fadeInLeft'"
          :style="`animation-delay: ${index * 400}ms`"
        >
          <span v-html="item.title"></span>
          <video class="video" controls :poster="item.image">
            <source :src="item.video" type="video/mp4" />
          </video>
        </div>
      </div>
    </div>
  </section>
</template>
<style lang="scss" scoped>
section.videos {
  position: relative;
  margin: 0 auto;
  background-color: #fff;

  .video-holder {
    position: relative;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    justify-content: space-between;
    align-items: end;
    margin: 0 auto;
    gap: 50px;

    .video-item {
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 45px;
      span {
        text-align: center;
      }
      .video {
        border-radius: 12px;
      }
    }
  }
}
</style>
