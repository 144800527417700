<script setup></script>
<template>
 <router-view />
</template>

<style lang="scss">
:root {
 --primary: #282828;
 --secondary: #efc207;
 --tertiary: #f5f3f3;
 --green1: #1ab43b;
 --yellow1: #f9cc00;
 --dark1: #050a0e;
 --dark2: #1a1a1a;
 --dark3: #383838;
 --dark4: #5a5a5a;
 --dark5: #868686;
 --dark-blue: #12141f;
 --dark-gradient: linear-gradient(0deg, #011c2a, #01111a);
 --red1: #eb2f2f;
 --box-shadow-default: 0 30px 54px 0 rgba(35, 54, 70, 0.12);
}

*:not(path) {
 border: none;
 outline: none;
 margin: 0;
 padding: 0;
 font-family: fontRegular;
 text-decoration: none;
 box-sizing: border-box;
 -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
 list-style: none;
}

html,
body {
 width: 100vw;
 min-height: 100vh;
 background-color: #fff;
 scroll-behavior: "smooth";
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
label,
a,
div {
 font-size: 16px;
 color: var(--primary);
}

.color-primary {
 color: var(--primary) !important;
}
.color-secondary {
 color: var(--secondary) !important;
}
.color-green1 {
 color: var(--vivid-green1) !important;
}
.color-yellow1 {
 color: var(--yellow1) !important;
}
.color-red1 {
 color: var(--red1) !important;
}

.desaturate {
 filter: saturate(0) !important;
}

@font-face {
 font-family: "fontRegular";
 src: url("../public/fonts/Poppins-Regular.ttf");
 font-display: swap;
}

@font-face {
 font-family: "fontLight";
 src: url("../public/fonts/Poppins-Light.ttf");
 font-display: swap;
}

@font-face {
 font-family: "fontMedium";
 src: url("../public/fonts/Poppins-Medium.ttf");
 font-display: swap;
}

@font-face {
 font-family: "fontBold";
 src: url("../public/fonts/Poppins-Bold.ttf");
 font-display: swap;
}

img {
 -webkit-user-select: none;
 -webkit-user-drag: none;
 -khtml-user-drag: none;
 -moz-user-drag: none;
 -o-user-drag: none;
 user-select: none;
}

.text-center {
 text-align: center;
}
.text-right {
 text-align: right;
}

.mr-left {
 margin-left: auto !important;
}
.mr-auto {
 margin-right: auto !important;
}

.flex {
 display: flex;
 position: relative;
 width: 100%;
 align-items: center;
}

.flex-between {
 display: flex;
 justify-content: space-between;
 flex-wrap: wrap;
}

.flex-start {
 display: flex;
 justify-content: flex-start;
 flex-wrap: wrap;
}

.flex-end {
 display: flex;
 justify-content: flex-end;
 flex-wrap: wrap;
}

.flex-column {
 display: flex;
 flex-direction: column;
}

.flex-center {
 position: relative;
 width: 100%;
 display: flex;
 justify-content: center;
 align-items: center;
 flex-wrap: wrap;
}

.bg-cover {
 position: fixed;
 width: 100vw;
 height: 100vh;
 background-color: rgba(0, 0, 0, 0.7);
 top: 0;
 left: 0;
 display: flex;
 justify-content: center;
 align-items: center;
 z-index: 999;
}

.divider {
 position: relative;
 width: 100%;
 height: 1px;
 margin: 35px auto;
 background-color: #e9e9e9;
}

.swiper-button-next,
.swiper-button-prev {
 color: var(--secondary) !important;
 z-index: 2;
 &::after {
  font-size: 1.6rem !important;
  font-weight: bold;
 }
}
.swiper-button-next {
 right: 50px !important;
 @media only screen and (max-width: 720px) {
  right: 20px !important;
 }
}
.swiper-button-prev {
 left: 50px !important;
 @media only screen and (max-width: 720px) {
  left: 20px !important;
 }
}

select {
 background: transparent;
}

.vs__search {
 background-color: transparent !important;
 margin: 0px !important;
 padding: 0.25rem 0.85rem !important;
 width: 0 !important;
 max-width: 100% !important;
}

.vs__dropdown-toggle {
 height: 44px !important;
 border-radius: 8px !important;
 padding: 0 !important;
 border: 1px solid #cccccc;
}

.vs__selected-options {
 flex-wrap: nowrap !important;
 span {
  font-size: 1rem;
  color: var(--primary) !important;
  font-family: fontRegular !important;
 }
}

.vs__search::placeholder {
 color: var(--dark5) !important;
 font-family: fontLight !important;
 font-size: 0.9rem !important;
}

.vs--single.vs--open .vs__selected,
.vs--single.vs--loading .vs__selected {
 position: relative !important;
}

.vs__clear {
 display: none !important;
}

.vs__open-indicator {
 margin-right: 6px;
}

.input-item {
 position: relative;
 width: 100%;
 margin: 1.35rem 0 0 0;

 //  span {
 //   position: relative;
 //   display: block;
 //   font-family: fontLight;
 //   font-size: 0.9rem;
 //   color: var(--primary);

 //   .blue {
 //    color: var(--blue);
 //   }
 //  }

 input,
 select,
 select option {
  position: relative;
  font-family: fontRegular;
  font-size: 1rem;
  width: 100%;
  height: 44px;
  border-radius: 8px;
  padding: 0.25rem 0.85rem;
  background-color: #eaeaea;
  color: var(--primary);
  &::placeholder {
   color: var(--dark5);
   font-family: fontLight;
   font-size: 1rem;
  }
 }
 select option:disabled {
  color: #aaa !important;
 }
 textarea {
  position: relative;
  width: 100%;
  height: 90px;
  padding: 0.25rem 0.85rem;
  background-color: #eaeaea;
  font-family: fontRegular;
  font-size: 1rem;
  color: var(--primary);
  resize: none;
  &::-webkit-scrollbar {
   width: 0.3em;
  }

  &::-webkit-scrollbar-track {
   -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
   box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  &::-webkit-scrollbar-thumb {
   background-color: var(--primary);
   outline: 1px solid slategrey;
  }
 }
}

.inputs-row .input-item {
 width: 45%;
}

.pointer {
 cursor: pointer;
}

.card-holder {
 position: relative;
 width: 100%;
 display: flex;
 flex-direction: column;
 justify-content: flex-start;
}

.card-header {
 position: relative;
 width: 100%;
 display: flex;
 align-items: center;
 justify-content: flex-start;
 gap: 30px;
 margin-bottom: 3rem;
 .item {
  position: relative;
  width: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0 0;
  width: 44px;
  height: 44px;
  border-radius: 14px;
  background-color: var(--secondary);
  span {
   transform: translate3d(8px, 8px, 0) scale(1.6);
  }
 }
 span {
  font-size: 1.05rem;
 }
}

.header-holder {
 display: flex;
 justify-content: flex-start;
 align-items: center;
 gap: 0.5rem;
 .icon-holder {
  padding: 0.5rem;
 }
}

.btn {
 position: relative;
 padding: 16px 22px;
 border-radius: 0.8em;
 background-color: var(--secondary);
 margin: 25px auto 0 auto;
 cursor: pointer;
 display: flex;
 justify-content: center;
 align-items: center;
 box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12);
 transition: transform 0.25s ease;
 &:hover {
  transform: translateY(-3px);
 }

 span {
  font-family: fontRegular;
  font-size: 1.1em;
  color: #000;
  text-align: center;
 }

 &.border {
  padding: 16px 22px;
  border: 1px solid var(--primary);
  background-color: transparent;
  box-shadow: none;
  span {
   color: var(--primary);
  }
 }

 &.inverse {
  padding: 16px 22px;
  background-color: transparent;
  box-shadow: none;
  span {
   color: var(--primary);
  }
 }

 &.icon {
  padding: 11px 22px;
  span:first-child {
   margin-right: 15px;
  }
 }
}

.material-design-icon {
 position: relative;
 top: 3px;
}

.loading {
 animation-duration: 0.8s;
 animation-iteration-count: infinite;
 animation-name: rotate-forever;
 animation-timing-function: linear;
 height: 22px;
 width: 22px;
 border: 2px solid var(--primary);
 border-right-color: transparent;
 border-radius: 50%;
 display: inline-block;
}

.loading.black {
 border: 2px solid #000;
 border-right-color: transparent;
}

.loading.white {
 border: 2px solid #fff;
 border-right-color: transparent;
}

@keyframes rotate-forever {
 0% {
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
 }

 100% {
  -webkit-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  -o-transform: rotate(360deg);
  transform: rotate(360deg);
 }
}

.page-anim1 {
 animation-name: anim1;
 animation-duration: 0.3s;
}

.page-anim2 {
 animation-name: anim2;
 animation-duration: 0.3s;
}

.page-anim3 {
 animation-name: anim3;
 animation-duration: 0.3s;
}

@keyframes anim1 {
 from {
  opacity: 0;
 }
 to {
  opacity: 1;
 }
}

@keyframes anim2 {
 from {
  opacity: 0;
  transform: translateX(17vw);
 }
 to {
  opacity: 1;
  transform: translateX(0vw);
 }
}

@keyframes anim3 {
 from {
  opacity: 0;
  transform: translateY(25vw);
 }
 to {
  opacity: 1;
  transform: translateY(0vw);
 }
}
</style>
